import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import styles from "./UpdateGuest.module.scss"
import { Stack, Chip, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Encrypt from '../../customHook/customHook/EncryptDecrypt/Encrypt';
import axiosInstanceAuth from '../../apiServices/axiosInstanceAuth';
import Decrypt from '../../customHook/customHook/EncryptDecrypt/Decrypt';
import { toast } from 'react-toastify';
import axiosInstance from '../../apiServices/axiosInstance';

const CreateGuestProfile = ({ coreQuestionData }) => {
    const isLoggedIn = localStorage.getItem("token") !== (null || undefined);

    const navigate = useNavigate();


    const filter = createFilterOptions();

    const [isData, setIsData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [candidate, setCandidate] = useState({
        first_name: "",
        last_name: "",
        email: "",
        // phone: "",
        job_title: "",
        employer: "",
        // current_company_url: "",
        // employer_type: "",
        time_in_current_role: "",
        time_in_industry: "",
        line_management: "",
        desired_employer_type: "",
        // current_country: "",
        // current_region: "",
        desired_country: "",
        desired_region: "",
        current_salary: "",
        willing_based: "",
        current_bonus_or_commission: "",
        desired_salary: "",
        desired_bonus_or_commission: "",
        notice_period: "",
        status: "",
        working_arrangements: "",
        // desired_working_arrangements: "",
        // freelance_current: "",
        freelance_future: "",
        freelance_daily_rate: "",

        // ====== Role Specific =====

        jurisdiction: "",
        pqe: "",
        area_of_law: "",
        legal_experience: "",

        deal_size: "",
        sales_quota: "",
        Profile: "",
        disability_specific: "",
    });

    const [isDisable, setIsDisable] = useState(true);
    const [industryTime, setIndustryTime] = useState("");
    const [currentRole, setCurrentRole] = useState("");
    // const [pqeTime, setPqeTime] = useState("");

    const [employersTypeListOptions, setEmployersTypeListOptions] = useState(null);
    const [sendEmpType, setSendEmpType] = useState(null);

    const [desiredEmployersTypeListOptions, setDesiredEmployersTypeListOptions] = useState([]);
    const [sendDesiredEmpType, setSendDesiredEmpType] = useState([]);

    const [countryOptions, setCountryOptions] = useState([]);
    const [sendCountry, setSendCountry] = useState(null);

    const [desireCountryOptions, setDesireCountryOptions] = useState([]);
    const [sendDesireCountry, setSendDesireCountry] = useState([]);

    const [regionOptions, setRegionOptions] = useState([]);
    const [sendRegion, setSendRegion] = useState(null);

    const [specificRegionOption, setspecificRegionOption] = useState([]);
    const [sendSpecificRegion, setSendSpecificRegion] = useState(null);

    const [desireRegionOptions, setDesireRegionOptions] = useState([]);
    const [sendDesireRegion, setSendDesireRegion] = useState([]);

    const [workingArrangementOptions, setWorkingArrangementOptions] = useState([]);
    const [sendWorkingArrangement, setSendWorkingArrangement] = useState(null);

    const [desiredWorkingArrangementOptions, setDesiredWorkingArrangementOptions] = useState([]);
    const [sendDesiredWorkingArrangement, setSendDesiredWorkingArrangement] = useState([]);

    const [customerTypesOptions, setCustomerTypesOptions] = useState([]);
    const [sendCustomerTypes, setSendCustomerTypes] = useState([]);

    const [legalTechToolsOptions, setLegalTechToolsOptions] = useState([]);
    const [sendLegalTechTools, setSendLegalTechTools] = useState([]);
    const [testsendLegalTechTools, setTestSendLegalTechTools] = useState([]);

    const [techToolsOptions, setTechToolsOptions] = useState([]);
    const [sendTechTools, setSendTechTools] = useState([]);
    const [testSendTechTools, setTestSendTechTools] = useState([]);

    const [qualificationsOptions, setQualificationsOptions] = useState([]);
    const [sendQualifications, setSendQualifications] = useState([]);
    const [testSendQualifications, setTestSendQualifications] = useState([]);

    const [langaugesOptions, setLangaugesOptions] = useState([]);
    const [sendLangauges, setSendLangauges] = useState([]);

    const freelanceOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendFreelance, setSendFreelance] = useState(null);

    const futureFreelanceOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendFutureFreelance, setSendFutureFreelance] = useState(null);

    const lawDegreeOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendLawDegree, setSendLawDegree] = useState(null);

    const qualifiedOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendQualified, setSendQualified] = useState(null);

    const legalExperienceOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendLegalExperience, setSendLegalExperience] = useState(null);

    const legalTechVendorOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendLegalTechVendor, setSendLegalTechVendor] = useState(null);

    const harrierSearchOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendHarrierSearch, setSendHarrierSearch] = useState(null);

    const harrierCandidatesOptions = [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" },
    ];
    const [sendHarrierCandidates, setSendHarrierCandidates] = useState(null);

    const [currancyOptions, setCurrancyOptions] = useState([]);

    const [sendCurrentSalarySymbol, setSendCurrentSalarySymbol] = useState(null);
    const [sendDesiredSalarySymbol, setSendDesiredSalarySymbol] = useState(null);
    const [sendCurrentBonusOrCommissionSymbol, setSendCurrentBonusOrCommissionSymbol] = useState(null);
    const [sendDesiredBonusOrCommissionSymbol, setSendDesiredBonusOrCommissionSymbol] = useState(null);
    const [sendFreelanceRateSymbol, setSendFreelanceRateSymbol] = useState(null);
    const [sendDealSizeSymbol, setSendDealSizeSymbol] = useState(null);
    const [sendSalesQuotaSymbol, setSendSalesQuotaSymbol] = useState(null);

    const [CvFileName, setCvFileName] = useState(null);

    const [cv, setCv] = useState(null);
    const [preview, setPreview] = useState(null);
    const [isShow, setIsShow] = useState('false')
    const [isGustUuid, setIsGustUuid] = useState(null)

    const onChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const name = e.target.name;
        setCandidate({
            ...candidate,
            [name]: value,
        });
    };

    useEffect(() => {
        candidateData();
        getEmployerTypeListOptions();
        getCuntryOptions();
        getDesiredCuntriesOptions();
        getCurrancyOptions();
        getWorkingArrangementOptions();
        getCustomerTypesOptions();
        getLegalTechToolsOptions();
        getTechToolsOptions();
        getQualificationsOptions();
        getLangaugesOptions();
    }, []);


    const candidateData = async () => {
        const encryptedData = Encrypt(
            JSON.stringify()
        );
        await axiosInstanceAuth
            .post("v1/guest/candidates/check", {
                response: encryptedData,
            })
            .then((res) => {
                const data = Decrypt(res.data.data);
                const msg = Decrypt(res?.data?.message).replace(/"/g, " ");
                const finalData = JSON.parse(data);
                if (res?.data?.success) {
                    if (finalData?.status == false) {
                        setIsShow('true')
                        setIsGustUuid(finalData?.uuid)
                        setIsLoading(false)
                    }
                } else {
                    toast.error(msg);
                }
            })
            .catch((err) => {
                console.log("err --->", err);
            });
    }

    const handleCvChange = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
            setCv(file);
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const getEmployerTypeListOptions = async () => {
        await axiosInstanceAuth
            .get(`/v1/list/mst_employer_types`)
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const employersTypeList = myData;
                if (res?.data?.success) {
                    setEmployersTypeListOptions(employersTypeList);
                    // setSendEmpType(employersTypeList.filter((x) => x.id == mydata?.employer_type)?.[0]);

                    setDesiredEmployersTypeListOptions(employersTypeList);

                    // let desEmpTypeRes = mydata?.desired_employer_type;
                    // setSendDesiredEmpType(employersTypeList.filter((x) => desEmpTypeRes.includes(x.id)));
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };

    const getSpecificRegionOptions = async (mydata, value) => {
        const encryptedData = Encrypt(
            JSON.stringify({
                country_id: value,
            })
        );
        await axiosInstanceAuth
            .post(`/v1/list/mst_regions`, {
                response: encryptedData,
            })
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const regionTypeList = myData?.list;

                if (res?.data?.success) {
                    setspecificRegionOption(regionTypeList);
                    setSendSpecificRegion(regionTypeList.filter((x) => x.id == mydata)?.[0]);
                } else {
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };

    const getCuntryOptions = async (mydata) => {
        await axiosInstanceAuth
            .post("/v1/countries/typelist")
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const CountriesList = myData?.list;

                if (res?.data?.success) {
                    setCountryOptions(CountriesList);
                    setSendCountry(CountriesList);

                    setIsData(mydata?.current_region);
                    getSpecificRegionOptions(
                        mydata?.current_region,
                        CountriesList.filter((x) => x.id == mydata?.current_country)?.[0]?.id
                    );
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };

    const getDesiredCuntriesOptions = async (mydata) => {
        await axiosInstanceAuth
            .get("/v1/list/mst_desired_countries")
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const DesiredCountriesList = myData;

                if (res?.data?.success) {
                    setDesireCountryOptions(DesiredCountriesList);
                    // let DesireCountryRes = mydata?.desired_country;
                    // setSendDesireCountry(DesiredCountriesList.filter((x) => DesireCountryRes.includes(x.id)));
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };

    const getCurrancyOptions = async (mydata) => {
        await axiosInstanceAuth
            .get("v1/list/mst_currencies")
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const CurrancyList = myData
                if (res?.data?.success) {
                    setCurrancyOptions(CurrancyList);
                    setSendCurrentSalarySymbol(
                        CurrancyList.filter((x) => x.id == mydata?.current_salary_symbol)?.[0]
                    );
                    setSendDesiredSalarySymbol(
                        CurrancyList.filter((x) => x.id == mydata?.desired_salary_symbol)?.[0]
                    );
                    setSendCurrentBonusOrCommissionSymbol(
                        CurrancyList.filter((x) => x.id == mydata?.current_bonus_or_commission_symbol)?.[0]
                    );
                    setSendDesiredBonusOrCommissionSymbol(
                        CurrancyList.filter((x) => x.id == mydata?.desired_bonus_or_commission_symbol)?.[0]
                    );
                    setSendFreelanceRateSymbol(
                        CurrancyList.filter((x) => x.id == mydata?.freelance_daily_rate_symbol)?.[0]
                    );
                    setSendDealSizeSymbol(CurrancyList.filter((x) => x.id == mydata?.deal_size_symbol)?.[0]);
                    setSendSalesQuotaSymbol(
                        CurrancyList.filter((x) => x.id == mydata?.sales_quota_symbol)?.[0]
                    );
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };
    const getWorkingArrangementOptions = async (mydata) => {
        await axiosInstanceAuth
            .get(`/v1/list/mst_working_arrangements`)
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const WorkingArrangementList = myData;

                if (res?.data?.success) {
                    setWorkingArrangementOptions(WorkingArrangementList);
                    setSendWorkingArrangement(
                        WorkingArrangementList.filter((x) => x.id == mydata?.working_arrangements)?.[0]
                    );

                    setDesiredWorkingArrangementOptions(WorkingArrangementList);
                    // setSendDesiredWorkingArrangement(mydata?.desired_working_arrangements)
                    let DesiredWorkingArrangementRes = mydata?.desired_working_arrangements;
                    // setSendDesiredWorkingArrangement(
                    //   WorkingArrangementList.filter((x) => DesiredWorkingArrangementRes.includes(x.id))
                    // );
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };
    const getCustomerTypesOptions = async (mydata) => {
        await axiosInstanceAuth
            .get(`/v1/list/mst_customer_types`)
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const CustomerTypesList = myData;
                if (res?.data?.success) {
                    setCustomerTypesOptions(CustomerTypesList);
                    // setSendCustomerTypes(mydata?.customer_type)
                    let CustomerTypesRes = mydata?.customer_type;
                    // setSendCustomerTypes(CustomerTypesList.filter((x) => CustomerTypesRes.includes(x.id)));
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };

    const getLegalTechToolsOptions = async (mydata) => {
        await axiosInstanceAuth
            .get(`/v1/list/mst_legal_tech_tools`)
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const LegalTechToolsList = myData;
                if (res?.data?.success) {
                    setLegalTechToolsOptions(LegalTechToolsList);
                    // setSendLegalTechTools(mydata?.legal_tech_tools)
                    let LegalTechToolsRes = mydata?.legal_tech_tools;
                    // setSendLegalTechTools(LegalTechToolsList.filter((x) => LegalTechToolsRes.includes(x.id)));

                    setTestSendLegalTechTools(LegalTechToolsRes);
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };

    const getTechToolsOptions = async (mydata) => {
        await axiosInstanceAuth
            .get(`/v1/list/mst_tech_tools`)
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const TechToolsList = myData;
                if (res?.data?.success) {
                    setTechToolsOptions(TechToolsList);
                    // setSendTechTools(mydata?.tech_tools)
                    let TechToolsRes = mydata?.tech_tools;
                    // setSendTechTools(TechToolsList.filter((x) => TechToolsRes.includes(x.id)));

                    setTestSendTechTools(TechToolsRes);
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };
    const getQualificationsOptions = async (mydata) => {
        await axiosInstanceAuth
            .get(`/v1/list/mst_qualifications`)
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const QualificationsList = myData;

                if (res?.data?.success) {
                    setQualificationsOptions(QualificationsList);
                    // setSendQualifications(mydata?.qualification)
                    let QualificationsRes = mydata?.qualification;
                    // setSendQualifications(QualificationsList.filter((x) => QualificationsRes.includes(x.id)));

                    setTestSendQualifications(QualificationsRes);
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };
    const getLangaugesOptions = async (mydata) => {
        await axiosInstanceAuth
            .get(`/v1/list/mst_languages`)
            .then((res) => {
                const myData = JSON.parse(Decrypt(res?.data?.data));
                const LangaugesList = myData;

                if (res?.data?.success) {
                    setLangaugesOptions(LangaugesList);
                    // setSendLangauges(mydata?.languages)
                    let LangaugesRes = mydata?.languages;
                    // setSendLangauges(LangaugesList.filter((x) => LangaugesRes.includes(x.id)));
                }
            })
            .catch((err) => {
                console.log("err--->", err);
            });
    };


    const DesiredEmpMap = sendDesiredEmpType && sendDesiredEmpType.map((d, i) => d.id);
    const DesireCountryMap = sendDesireCountry && sendDesireCountry.map((d, i) => d.id);
    const DesireRegionMap = sendDesireRegion && sendDesireRegion.map((d, i) => d.id);
    const DesiredWorkingArrangementMap =
        sendDesiredWorkingArrangement && sendDesiredWorkingArrangement.map((d, i) => d.id);
    const CustomerTypesMap = sendCustomerTypes && sendCustomerTypes.map((d, i) => d.id);
    const LegalTechToolsMap = sendLegalTechTools && sendLegalTechTools.map((d, i) => d.id);
    const TechToolsMap = sendTechTools && sendTechTools.map((d, i) => d.id);
    const QualificationsMap = sendQualifications && sendQualifications.map((d, i) => d.id);
    const LangaugesMap = sendLangauges && sendLangauges.map((d, i) => d.id);


    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [candidate?.profile_about]);
    // const successhandleOpen = () => setSuccessOpen(true);

    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = Encrypt(
            JSON.stringify({
                uuid: coreQuestionData.uuid,
                first_name: candidate.first_name,
                last_name: candidate.last_name,
                email: candidate.email,
                job_title: candidate.job_title,
                employer: candidate.employer,
                employer_type: sendEmpType?.id,
                time_in_current_role: currentRole,
                time_in_industry: industryTime,
                line_management: candidate.line_management,
                desired_employer_type: sendDesiredEmpType.map((item) => item.id),
                current_country: sendCountry?.id,

                current_region: sendSpecificRegion?.id,

                desired_country: sendDesireCountry.map((item) => item.id),

                desired_region: candidate.desired_region,
                current_salary: candidate.current_salary,
                current_salary_symbol: sendCurrentSalarySymbol.id,

                current_bonus_or_commission: candidate.current_bonus_or_commission,
                current_bonus_or_commission_symbol: sendCurrentBonusOrCommissionSymbol.id,

                desired_salary: candidate.desired_salary,
                desired_salary_symbol: sendDesiredSalarySymbol.id,

                desired_bonus_or_commission: candidate.desired_bonus_or_commission,
                desired_bonus_or_commission_symbol: sendDesiredBonusOrCommissionSymbol.id,

                notice_period: candidate.notice_period,
                working_arrangements: sendWorkingArrangement.id,
                desired_working_arrangements: sendDesiredWorkingArrangement.map((item) => item.id),
                freelance_current: sendFreelance.id,
                freelance_future: sendFutureFreelance.id,
                freelance_daily_rate: candidate.freelance_daily_rate,
                freelance_daily_rate_symbol: sendFreelanceRateSymbol.id,

                law_degree: sendLawDegree.title === "yes" ? 1 : 0,
                qualified_lawyer: sendQualified.title === "yes" ? 1 : 0,
                jurisdiction: candidate.jurisdiction,
                pqe: candidate.pqe,
                area_of_law: candidate.area_of_law,
                legal_experience: candidate.legal_experience,
                customer_type: sendCustomerTypes.map((item) => item.id),
                deal_size: candidate.deal_size,
                deal_size_symbol: sendDealSizeSymbol.id,
                sales_quota: candidate.sales_quota,
                sales_quota_symbol: sendSalesQuotaSymbol.id,
                languages: sendLangauges.map((item) => item.id),
                legal_tech_tools: testsendLegalTechTools,
                tech_tools: testSendTechTools,
                qualification: testSendQualifications,
                profile_about: candidate.profile_about,
                legaltech_vendor_or_consultancy: sendLegalTechVendor.title === "yes" ? 1 : 0,
                harrier_search: sendHarrierSearch.title == "yes" ? 1 : 0,
                harrier_candidate: sendHarrierCandidates.title == "yes" ? 1 : 0,
                cv: cv ? cv : ""
            })
        );
        const formData = new FormData();
        formData.append("response", response);
        // formData.append("cv", cv == undefined ? "" : cv);

        await axiosInstance
            .post('v1/candidate/new/form/create', formData)
            .then((res) => {
                const data = Decrypt(res.data.data);
                const finalData = JSON.parse(data);
                const msg = Decrypt(res?.data?.message).replace(/"/g, " ");
                if (res.data.success) {
                    navigate('/guest-dashboard')
                    // localStorage.setItem("token", finalData.access_token)
                    toast.success(msg)
                    // successhandleOpen();
                } else {
                    toast.error(msg);
                }
            })
            .catch((err) => {
                console.log(Error);
                // toast.error("Error")
            });
    };
    return (
        <>
            <div className='container mt-3'>
                <form>
                    <div className={`${styles['candidates-details-container']} container`}>
                        <div className='row flex-column flex-lg-row'>
                            <div className='col-lg-6 col-12 p-0 p-md-2'>
                                <div className="core-que-container m-0 m-md-2">
                                    <h3 className="heading">Core Questions</h3>
                                    <div className="detail-content">
                                        <h3 className="title">Current Company</h3>
                                        <input
                                            type="text"
                                            name="employer"
                                            onChange={onChange}
                                            value={candidate.employer || ""}
                                            className="text-input-field form-control "
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">First Name</h3>
                                        <input
                                            type="text"
                                            name="first_name"
                                            onChange={onChange}
                                            value={candidate?.first_name || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Last Name</h3>
                                        <input
                                            type="text"
                                            name="last_name"
                                            onChange={onChange}
                                            value={candidate?.last_name || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Email</h3>
                                        <input
                                            type="email"
                                            name="email"
                                            onChange={onChange}
                                            value={candidate.email || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Job Title</h3>
                                        <input
                                            type="text"
                                            name="job_title"
                                            onChange={onChange}
                                            value={candidate.job_title || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Employer Type </h3>
                                        <Autocomplete
                                            options={employersTypeListOptions}
                                            getOptionLabel={(option) => option.title}
                                            value={sendEmpType}
                                            onChange={(e, value) => {
                                                setSendEmpType(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.title === value?.title}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Time in Current Role</h3>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={2} className="text-input-field">
                                                <DesktopDatePicker
                                                    value={currentRole}
                                                    minDate={dayjs("2000-01-01")}
                                                    onChange={(newValue) => {
                                                        const inFormat = dayjs(newValue).format("YYYY-MM-DD");
                                                        setCurrentRole(inFormat);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    className="text-input-field time-input"
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Time in Industry</h3>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3} className="text-input-field">
                                                <DesktopDatePicker
                                                    value={industryTime}
                                                    minDate={dayjs("2000-01-01")}
                                                    onChange={(newValue) => {
                                                        const inFormat = dayjs(newValue).format("YYYY-MM-DD");
                                                        setIndustryTime(inFormat);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    className="text-input-field time-input"
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Line Management</h3>
                                        <input
                                            type="text"
                                            name="line_management"
                                            onChange={onChange}
                                            value={candidate.line_management || ""}
                                            className="text-input-field form-control "
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Desired Employer Type</h3>
                                        <Autocomplete
                                            id="tags-standard"
                                            multiple
                                            options={desiredEmployersTypeListOptions}
                                            getOptionLabel={(option) => option.title}
                                            value={sendDesiredEmpType || []}
                                            onChange={(e, value) => {
                                                setSendDesiredEmpType(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.title === value?.title}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field "
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Current Country</h3>
                                        <Autocomplete
                                            options={countryOptions}
                                            getOptionLabel={(option) => option.country_name}
                                            value={sendCountry || {}}
                                            onChange={(e, value) => {
                                                setSendCountry(value);
                                                getSpecificRegionOptions(isData, value?.id);
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.country_name === value?.country_name}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field "
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Current Region</h3>
                                        <Autocomplete
                                            options={specificRegionOption}
                                            getOptionLabel={(option) => option.state_name}
                                            value={sendSpecificRegion || {}}
                                            onChange={(e, value) => {
                                                setSendSpecificRegion(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.state_name === value?.state_name}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field "
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Desired Country</h3>
                                        <Autocomplete
                                            multiple
                                            options={desireCountryOptions}
                                            getOptionLabel={(option) => option.country_name}
                                            value={sendDesireCountry || []}
                                            onChange={(e, value) => {
                                                setSendDesireCountry(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.country_name === value?.country_name}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field "
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Desired Region</h3>
                                        <input
                                            type="text"
                                            name="desired_region"
                                            onChange={onChange}
                                            value={candidate.desired_region || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Current Salary</h3>
                                        <div className="input-field-wrapper">
                                            <input
                                                type="text"
                                                name="current_salary"
                                                onChange={onChange}
                                                value={candidate.current_salary || ""}
                                                className="text-input-field form-control"
                                            />
                                            <Autocomplete
                                                options={currancyOptions}
                                                getOptionLabel={(option) => option.currency_code}
                                                value={sendCurrentSalarySymbol || {}}
                                                onChange={(e, value) => {
                                                    setSendCurrentSalarySymbol(value);
                                                }}
                                                isOptionEqualToValue={(option, value) => option?.currency_code === value?.currency_code}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                className="symbol-input-field"
                                                classes={{ root: "override-input-field" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Desired Salary</h3>
                                        <div className="input-field-wrapper">
                                            <input
                                                type="text"
                                                name="desired_salary"
                                                onChange={onChange}
                                                value={candidate.desired_salary || ""}
                                                className="text-input-field form-control"
                                            />
                                            <Autocomplete
                                                options={currancyOptions}
                                                getOptionLabel={(option) => option.currency_code}
                                                value={sendDesiredSalarySymbol || {}}
                                                onChange={(e, value) => {
                                                    setSendDesiredSalarySymbol(value);
                                                }}
                                                isOptionEqualToValue={(option, value) => option?.currency_code === value?.currency_code}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                className="symbol-input-field"
                                                classes={{ root: "override-input-field" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Current Bonus / Commission</h3>
                                        <div className="input-field-wrapper">
                                            <input
                                                type="text"
                                                name="current_bonus_or_commission"
                                                onChange={onChange}
                                                value={candidate.current_bonus_or_commission || ""}
                                                className="text-input-field form-control"
                                            />
                                            <Autocomplete
                                                options={currancyOptions}
                                                getOptionLabel={(option) => option.currency_code}
                                                value={sendCurrentBonusOrCommissionSymbol}
                                                onChange={(e, value) => {
                                                    setSendCurrentBonusOrCommissionSymbol(value);
                                                }}
                                                isOptionEqualToValue={(option, value) => option?.currency_code === value?.currency_code}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                className="symbol-input-field"
                                                classes={{ root: "override-input-field" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Desired Bonus / Commission</h3>
                                        <div className="input-field-wrapper">
                                            <input
                                                type="text"
                                                name="desired_bonus_or_commission"
                                                onChange={onChange}
                                                value={candidate.desired_bonus_or_commission || ""}
                                                className="text-input-field form-control"
                                            />
                                            <Autocomplete
                                                options={currancyOptions}
                                                getOptionLabel={(option) => option.currency_code}
                                                value={sendDesiredBonusOrCommissionSymbol}
                                                onChange={(e, value) => {
                                                    setSendDesiredBonusOrCommissionSymbol(value);
                                                }}
                                                isOptionEqualToValue={(option, value) => option?.currency_code === value?.currency_code}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                className="symbol-input-field"
                                                classes={{ root: "override-input-field" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Notice Period</h3>
                                        <input
                                            type="text"
                                            name="notice_period"
                                            onChange={onChange}
                                            value={candidate.notice_period || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    {/* <div className="detail-content">
                    <h3 className="title">Status</h3>
                    <div className="text-input-field">
                      {candidate?.status == 1 ? (
                        <span className="bg-Color-success fw-bold">Active</span>
                      ) : candidate?.status == 2 ? (
                        <span className="bg-Color-warning fw-bold">Passive</span>
                      ) : candidate?.status == 3 ? (
                        <span className="bg-Color-info fw-bold">Very Passive</span>
                      ) : candidate?.status == 4 ? (
                        <span className="bg-Color-error fw-bold">Closed</span>
                      ) : null}
                    </div>
                  </div> */}
                                    <div className="detail-content">
                                        <h3 className="title">Freelance (Current)</h3>
                                        <Autocomplete
                                            options={freelanceOptions}
                                            getOptionLabel={(option) => option.title}
                                            value={sendFreelance || {}}
                                            onChange={(e, value) => {
                                                setSendFreelance(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.title === value?.title}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Freelance (Future)</h3>
                                        <Autocomplete
                                            options={futureFreelanceOptions}
                                            getOptionLabel={(option) => option.title}
                                            value={sendFutureFreelance || {}}
                                            onChange={(e, value) => {
                                                setSendFutureFreelance(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option?.title === value?.title}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Freelance rate (daily rate)</h3>
                                        <div className="input-field-wrapper">
                                            <input
                                                type="text"
                                                name="freelance_daily_rate"
                                                onChange={onChange}
                                                value={candidate.freelance_daily_rate || ""}
                                                className="text-input-field form-control"
                                            />
                                            <Autocomplete
                                                options={currancyOptions}
                                                getOptionLabel={(option) => option.currency_code || ""}
                                                value={sendFreelanceRateSymbol || {}}
                                                onChange={(e, value) => {
                                                    setSendFreelanceRateSymbol(value);
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                className="symbol-input-field"
                                                classes={{ root: "override-input-field" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Working Arrangements</h3>
                                        <Autocomplete
                                            options={workingArrangementOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendWorkingArrangement || {}}
                                            onChange={(e, value) => {
                                                setSendWorkingArrangement(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Desired Working Arrangements</h3>
                                        <Autocomplete
                                            multiple
                                            options={desiredWorkingArrangementOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendDesiredWorkingArrangement || []}
                                            onChange={(e, value) => {
                                                setSendDesiredWorkingArrangement(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 p-0 p-md-2 mt-md-0 mt-2'>
                                <div className="role-que-container m-0 m-md-2">
                                    <h3 className="heading">Role Questions</h3>
                                    <h3 className="sub-heading">Legal</h3>

                                    <div className="detail-content">
                                        <h3 className="title">Law Degree</h3>
                                        <Autocomplete
                                            options={lawDegreeOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendLawDegree || {}}
                                            onChange={(e, value) => {
                                                setSendLawDegree(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Qualified Lawyer</h3>
                                        <Autocomplete
                                            options={qualifiedOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendQualified || {}}
                                            onChange={(e, value) => {
                                                setSendQualified(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Jurisdiction</h3>
                                        <input
                                            type="text"
                                            name="jurisdiction"
                                            onChange={onChange}
                                            value={candidate.jurisdiction || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">PQE</h3>

                                        <input
                                            type="text"
                                            name="pqe"
                                            onChange={onChange}
                                            value={candidate.pqe || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Legal Experience</h3>
                                        <input
                                            type="text"
                                            name="legal_experience"
                                            onChange={onChange}
                                            value={candidate.legal_experience || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Area of Law</h3>
                                        <input
                                            type="text"
                                            name="area_of_law"
                                            onChange={onChange}
                                            value={candidate.area_of_law || ""}
                                            className="text-input-field form-control"
                                        />
                                    </div>

                                    <h3 className="sub-heading">Commercial</h3>
                                    <div className="detail-content">
                                        <h3 className="title">LegalTech vendor/consultancy</h3>
                                        <Autocomplete
                                            options={legalTechVendorOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendLegalTechVendor || []}
                                            onChange={(e, value) => {
                                                setSendLegalTechVendor(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Customer Type</h3>
                                        <Autocomplete
                                            multiple
                                            options={customerTypesOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendCustomerTypes || []}
                                            onChange={(e, value) => {
                                                setSendCustomerTypes(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Deal Size</h3>
                                        <div className="input-field-wrapper">
                                            <input
                                                type="text"
                                                name="deal_size"
                                                onChange={onChange}
                                                value={candidate.deal_size || ""}
                                                className="text-input-field form-control"
                                            />
                                            <Autocomplete
                                                options={currancyOptions}
                                                getOptionLabel={(option) => option.currency_code || ""}
                                                value={sendDealSizeSymbol || {}}
                                                onChange={(e, value) => {
                                                    setSendDealSizeSymbol(value);
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                className="symbol-input-field"
                                                classes={{ root: "override-input-field" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Sales quota</h3>
                                        <div className="input-field-wrapper">
                                            <input
                                                type="text"
                                                name="sales_quota"
                                                onChange={onChange}
                                                value={candidate.sales_quota || ""}
                                                className="text-input-field form-control"
                                            />
                                            <Autocomplete
                                                options={currancyOptions}
                                                getOptionLabel={(option) => option.currency_code || ""}
                                                value={sendSalesQuotaSymbol || {}}
                                                onChange={(e, value) => {
                                                    setSendSalesQuotaSymbol(value);
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                className="symbol-input-field"
                                                classes={{ root: "override-input-field" }}
                                            />
                                        </div>
                                    </div>
                                    <h3 className="sub-heading">Tech/Other Qualifications</h3>
                                    <div className="detail-content">
                                        <h3 className="title">LegalTech Tools</h3>

                                        <Autocomplete
                                            multiple
                                            options={legalTechToolsOptions.map((option) => option.title)}
                                            value={testsendLegalTechTools || []}

                                            onChange={(e, value) => {
                                                setTestSendLegalTechTools(value);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;

                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);

                                                if (inputValue !== "" && !isExisting) {
                                                    filtered.push(inputValue);
                                                }
                                                return filtered;
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Tech Tools</h3>

                                        <Autocomplete
                                            multiple
                                            options={techToolsOptions.map((option) => option.title)}
                                            value={testSendTechTools || []}

                                            onChange={(e, value) => {
                                                setTestSendTechTools(value);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;

                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);

                                                if (inputValue !== "" && !isExisting) {
                                                    filtered.push(inputValue);
                                                }
                                                return filtered;
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Qualifications</h3>

                                        <Autocomplete
                                            multiple
                                            options={qualificationsOptions.map((option) => option.title)}
                                            value={testSendQualifications || []}
                                            onChange={(e, value) => {
                                                setTestSendQualifications(value);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;

                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);

                                                if (inputValue !== "" && !isExisting) {
                                                    filtered.push(inputValue);
                                                }
                                                return filtered;
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Languages</h3>
                                        <Autocomplete
                                            multiple
                                            options={langaugesOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendLangauges || []}
                                            onChange={(e, value) => {
                                                setSendLangauges(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                    <div className="detail-content">
                                        <h3 className="title">Profile</h3>
                                        <textarea
                                            ref={textareaRef}
                                            type="text"
                                            name="profile_about"
                                            onChange={onChange}
                                            value={candidate.profile_about || ""}
                                            className="text-input-area form-control"
                                        />
                                    </div>
                                    <div className="detail-content-symbol">
                                        <h3 className="title">Upload CV</h3>
                                        <div className="input-field-wrapper shadow-none">
                                            <input name="cv" onChange={handleCvChange}
                                                className="form-control shadow-none" type="file" id="formFile" />
                                        </div>
                                    </div>

                                    <div className="detail-content">
                                        <h3 className="title">Harrier Search</h3>
                                        <Autocomplete
                                            options={harrierSearchOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendHarrierSearch || {}}
                                            onChange={(e, value) => {
                                                setSendHarrierSearch(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>

                                    <div className="detail-content">
                                        <h3 className="title">Harrier Candidates</h3>
                                        <Autocomplete
                                            options={harrierCandidatesOptions}
                                            getOptionLabel={(option) => option.title || ""}
                                            value={sendHarrierCandidates || {}}
                                            onChange={(e, value) => {
                                                setSendHarrierCandidates(value);
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            className="text-input-field"
                                        />
                                    </div>
                                </div>
                                <div className='mt-4 text-end'>
                                    <button
                                        className="commom-sky-button"
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}

export default CreateGuestProfile